export type FeatureFlag =
  | 'movieAttachment'
  | 'YTDFilter'
  | 'enableTermsAndConditions'
  | 'enableKilnMillGlobalStatus'
  | 'enableDowntimeProductionTime'
  | 'checklists'
  | 'enableSplitStoppage'

// define here, which Flags should be enabled for each REACT_APP_STAGE
const stageSpecific: Record<string, FeatureFlag[]> = {
  qa: ['movieAttachment', 'enableKilnMillGlobalStatus', 'checklists', 'enableSplitStoppage'],
  uat: ['movieAttachment', 'checklists', 'enableSplitStoppage'],
  prod: ['movieAttachment', 'checklists', 'enableSplitStoppage']
}

// Note: stage is passed from cypress to check feature flags for each stage
// https://gitlab.heidelbergcement.com/hcp/monorepo/-/blob/a32cd2f1130a677811a118d4cfe5b13e67e1de60/packages/automate/src/modules/release/cypress.ts#L37
// Included when cypress tests are running on server
export const checkFeatureFlag = (flag: FeatureFlag, stage = process.env.REACT_APP_STAGE) => {
  const stageSpecificFlags = (stage && stageSpecific[stage]) || []
  return stageSpecificFlags.includes(flag)
}
