import {areMainEquipmentsSame} from '@hconnect/common/components/shiftEventFormFields'
import {getEquipmentLabel} from '@hconnect/common/components/shiftEventLabels'
import {EquipmentData} from '@hconnect/common/types'
import {mergeSxs} from '@hconnect/uikit/src/lib2'
import {Autocomplete} from '@mui/lab'
import {Checkbox, MenuItem, Paper, TextField} from '@mui/material'
import {AxiosError} from 'axios'
import {debounce} from 'lodash'
import React, {useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {UseQueryOptions, UseQueryResult} from 'react-query'

import {useTranslationPrefix} from '../../hooks/useTranslationPrefix'

import {textFieldOnBlueSx} from './filters/filterStyles'

export const SEARCH_DELAY_IN_MS = 500

export type Props = {
  plantId: string
  onChange: (equipment: EquipmentData[]) => void
  value?: EquipmentData[]
  clearFilter: () => void
  noEquipmentsSelectedLabelKey: string
  selectedEquipmentsLabelKey: string
  dataTestId?: string
  useApiHook: (
    plantId: string,
    searchQuery?: string,
    options?: UseQueryOptions<EquipmentData[], AxiosError>
  ) => UseQueryResult<EquipmentData[], AxiosError<unknown>>
}

const paper = (props) => <Paper elevation={8} sx={{minWidth: '350px'}} {...props} />

export const EquipmentSearchField: React.FC<Props> = ({
  plantId,
  onChange,
  value: selectedEquipments,
  clearFilter,
  noEquipmentsSelectedLabelKey,
  selectedEquipmentsLabelKey,
  dataTestId = 'filter-mainEquipment',
  useApiHook
}: Props) => {
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const [searchTerm, setSearchTerm] = React.useState<string>('')
  const [delayedSearchText, setDelayedSearchText] = React.useState<string>('')

  const {data: response, isLoading} = useApiHook(plantId, delayedSearchText)

  const debounceSetSearchTerm = debounce((text: string) => {
    setDelayedSearchText(text)
  }, SEARCH_DELAY_IN_MS)

  useEffect(() => {
    const hasAllData = selectedEquipments?.every((eq) => eq.id && eq.text)
    if (selectedEquipments?.length && !hasAllData && response?.length) {
      const selectedEquipmentsWithDesc: EquipmentData[] = []
      for (const selectedEq of selectedEquipments) {
        const responseEquipment: EquipmentData | undefined = response.find(
          (eq) => eq.id === selectedEq.id
        )
        selectedEquipmentsWithDesc.push(responseEquipment || selectedEq)
      }
      onChange(selectedEquipmentsWithDesc)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response])

  const suggestions = useMemo(() => {
    const data = response || []
    let finalEquipmentList: EquipmentData[] = []

    if (selectedEquipments && !data.length) {
      // show only selected equipments
      finalEquipmentList = selectedEquipments
    } else if (selectedEquipments && data.length) {
      // filter the selected equipments from response and show on top
      const orderedEquipments: EquipmentData[] = []
      for (const eq of selectedEquipments) {
        const matchingEquip = data.find((responseEquipment) => eq.id === responseEquipment.id)
        orderedEquipments.push(matchingEquip || eq)
      }
      const remainingEquipments = data.filter(
        (equipment) => !orderedEquipments.some((orderedEq) => orderedEq.id === equipment.id)
      )
      finalEquipmentList = orderedEquipments.concat(remainingEquipments)
    } else {
      // show list of suggestion from api
      finalEquipmentList = data
    }

    return finalEquipmentList
  }, [selectedEquipments, response])

  return (
    <Autocomplete
      data-test-id={dataTestId}
      multiple
      value={selectedEquipments}
      inputValue={searchTerm}
      onChange={(event, next, reason) => {
        if (reason === 'clear') {
          clearFilter()
          setSearchTerm('')
          debounceSetSearchTerm('')
        } else onChange(next)
      }}
      onInputChange={(event, newValue, reason) => {
        if (reason === 'input') {
          setSearchTerm(newValue)
          debounceSetSearchTerm(newValue)
        }
      }}
      disableCloseOnSelect
      options={suggestions}
      sx={{
        marginRight: {xs: 2, md: 0}
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t(`${performancePrefix}.pleaseStartTyping`)}
          fullWidth={false}
          onChange={(event) => setSearchTerm(event.target.value)}
          sx={mergeSxs(
            {
              '& label': {
                width: 'calc(100% - 32px)',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden'
              }
            },
            textFieldOnBlueSx
          )}
          variant="filled"
          label={
            !selectedEquipments?.length
              ? t(noEquipmentsSelectedLabelKey)
              : t(selectedEquipmentsLabelKey, {
                  equipments: selectedEquipments.map((opt) => opt.text || opt.id).join(', ')
                })
          }
        />
      )}
      PaperComponent={paper}
      renderTags={() => ''}
      renderOption={(props, option) => {
        return (
          <MenuItem {...props} key={option.id} data-test-id={`eq-${option.id}`}>
            <Checkbox
              checked={!!selectedEquipments?.find((eq) => eq.id === option.id)}
              // @hproduce: fixed below to avoid [object Object] in string literal
              data-test-id={`eq-checkbox-${option.id}`}
            />
            {getEquipmentLabel(option, true) || option.id}
          </MenuItem>
        )
      }}
      getOptionLabel={(equipment) =>
        equipment ? getEquipmentLabel(equipment, true) || equipment.id : '-'
      }
      loading={isLoading}
      filterOptions={(options) => options}
      isOptionEqualToValue={areMainEquipmentsSame}
    />
  )
}
