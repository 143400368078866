import {appUrl} from '@hconnect/common/hproduce/config'
import {MAINTAIN_HOST} from '@hconnect/common/hproduce/config/maintain'
import {calcRelativeValueInPercent, dataTestId} from '@hconnect/uikit'
import {CardBox, Column, DataTable} from '@hconnect/uikit/src/lib2'
import {ChevronRight} from '@mui/icons-material'
import {Box, Grid, Typography, Link} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath} from 'react-router-dom'

import {useRcfaAvailability} from '../hooks/useRcfaAvailability'
import {usePlantTimeRange} from '../hooks/useTimeRange'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {AggregatedStoppageRcfaAvailability} from '../types'

type RcfaTableItem = {
  column: string
  absoluteNumber: number
  percentage: number
}

export interface RcfaTableProps {
  plantId: string
}

const mapAggregatedStoppageRcfaAvailabilityToRcfa = (
  data: AggregatedStoppageRcfaAvailability,
  translationFn: (key: string) => string
): RcfaTableItem[] => {
  const result: RcfaTableItem[] = []
  if (!data) {
    return result
  }
  const missingRcfas = data.longKilnStoppageCount - data.longKilnStoppageRcfaCount
  const optionalRcfas = data.stoppageRcfaCount - data.longKilnStoppageRcfaCount
  const kilnStoppages = data.kilnStoppageCount

  const total = missingRcfas + optionalRcfas + kilnStoppages

  result.push({
    column: translationFn('downtime.label.missingRCFAs'),
    absoluteNumber: missingRcfas,
    percentage:
      missingRcfas > 0 ? Math.round(calcRelativeValueInPercent(missingRcfas, 0, total)) : 0
  })
  result.push({
    column: translationFn('downtime.label.optionalRCFAs'),
    absoluteNumber: optionalRcfas,
    percentage:
      optionalRcfas > 0 ? Math.round(calcRelativeValueInPercent(optionalRcfas, 0, total)) : 0
  })
  result.push({
    column: translationFn('downtime.label.kilnStoppages'),
    absoluteNumber: kilnStoppages,
    percentage:
      kilnStoppages > 0 ? Math.round(calcRelativeValueInPercent(kilnStoppages, 0, total)) : 0
  })
  return result
}

export const RcfaTable: React.FC<RcfaTableProps> = ({plantId}) => {
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const {from, to} = usePlantTimeRange()
  const {data: rcfaData} = useRcfaAvailability(plantId, {
    startDate: from.toJSON(),
    endDate: to.toJSON()
  })

  const columns: Column<RcfaTableItem>[] = [
    {
      key: 'column',
      label: t(`${performancePrefix}.downtime.label.column`),
      customTemplate: ({column}) => <Typography variant="h5">{column}</Typography>
    },
    {
      key: 'absoluteNumber',
      label: t(`${performancePrefix}.downtime.label.absoluteNumber`),
      customTemplate: ({absoluteNumber}) => <Typography variant="h5">{absoluteNumber}</Typography>
    }
  ]

  const linkPath = `${appUrl(MAINTAIN_HOST)}${generatePath('/plant/:plantId/rcfa/stoppages', {
    plantId
  })}`

  if (!rcfaData) {
    return null
  }

  return (
    <Link
      href={linkPath}
      data-test-id="rcfa-card-link-to-maintain"
      sx={(theme) => ({
        textDecoration: 'none',
        '&:hover': {
          '& .card-chevron, .card-name': {
            color: theme.palette.primary.main
          }
        }
      })}
    >
      <CardBox>
        <Box display="flex" flexDirection="row">
          <Box flex={1}>
            <Typography variant="h3">
              {t(`${performancePrefix}.downtime.label.rcfaStatus`)}
            </Typography>
          </Box>
          <Box className={'card-chevron'}>
            <ChevronRight />
          </Box>
        </Box>

        <Grid display="flex" justifyContent="space-between" marginTop={2}>
          <DataTable<RcfaTableItem>
            data={mapAggregatedStoppageRcfaAvailabilityToRcfa(rcfaData, (key) =>
              t(`${performancePrefix}.${key}`)
            )}
            columns={columns}
            {...dataTestId('rcfaTable')}
            keyExtractor={(item) => item.column}
          />
        </Grid>
      </CardBox>
    </Link>
  )
}
