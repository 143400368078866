import {generatePath} from 'react-router'

import {getCountries} from '../../performanceDashboard/helpers/location.helpers'
import {LOCATION_KPI, LOCATION_KPI_STATUS, PLANT_KPI, PLANT_KPI_STATUS} from '../routes/routes'
import {
  NavigationAccess,
  AccessiblePlantIds,
  AccessibleLocations,
  AccessStatus
} from '../types/navigationAccess.types'

export class NavAccessContext {
  public readonly accessStatus: AccessStatus
  public readonly allowedLocations: AccessibleLocations
  public readonly allowedPlants: AccessiblePlantIds

  constructor(navAccessData: NavigationAccess) {
    const {status, accessibleLocations, accessiblePlantIds} = navAccessData
    this.accessStatus = status
    this.allowedLocations = accessibleLocations || []
    this.allowedPlants = accessiblePlantIds || []
  }

  /**
   * check if the location(region/country) should be accessible to the user
   * @param locationId
   * @returns boolean
   */
  public isLocationAllowed = (locationId: string) => {
    return this.allowedLocations?.some((location) => location === locationId)
  }

  /**
   * check if the plant should be accessible to the user
   * @param plantId unique plant identifier
   * @returns
   */
  public isPlantAllowed = (plantId: string) => {
    return this.allowedPlants?.some((plant) => plant === plantId)
  }

  /**
   * Checks if a user is allowed to access more than one country or region
   * @returns boolean
   */
  public allowMultipleLocations = () => {
    return this.accessStatus === AccessStatus.MultiLocationsAccess
  }

  /**
   * Checks if a user is allowed to access more than one plant
   * @returns boolean
   */
  public allowMultiplePlants = () => {
    if (this.allowMultipleLocations()) {
      return true
    } else if (this.allowedPlants.length > 1) {
      return true
    } else {
      return false
    }
  }

  /**
   * Provides with a redirect url if the user has access to a a single plant or region
   * @returns string(url) | null
   */
  public getRedirectURL = () => {
    if (this.accessStatus === AccessStatus.SinglePlantAccess) {
      const [accessiblePlant] = this.allowedPlants
      return generatePath(PLANT_KPI, {
        plantId: accessiblePlant
      })
    } else if (this.accessStatus === AccessStatus.SingleCountryAccess) {
      const countries = getCountries(this.allowedLocations)
      const [accessibleCountry] = countries
      return accessibleCountry ? generatePath(LOCATION_KPI, {locationId: accessibleCountry}) : null
    }
    return null
  }

  /**
   * Provides with a redirect status url if the user has access to a single plant or region
   * @returns string(url) | null
   */
  public getRedirectStatusURL = () => {
    if (this.accessStatus === AccessStatus.SinglePlantAccess) {
      const [accessiblePlant] = this.allowedPlants

      return generatePath(PLANT_KPI_STATUS, {
        plantId: accessiblePlant
      })
    } else if (this.accessStatus === AccessStatus.SingleCountryAccess) {
      const countries = getCountries(this.allowedLocations)
      const [accessibleCountry] = countries

      return accessibleCountry
        ? generatePath(LOCATION_KPI_STATUS, {locationId: accessibleCountry})
        : null
    }
    return null
  }
}
