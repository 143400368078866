import {StoppageKind, StoppageType} from '@hconnect/common/types'
import {DateRange} from '@hconnect/uikit'
import {orderBy} from 'lodash'
import {useCallback, useState} from 'react'

import {TOP_ISSUES_GROUP_BY, TOP_ISSUES_SORT_BY} from '../consts'
import {
  useGetEventNamesByRoutePath,
  useTrackDowntimeAnalyticsEvent
} from '../helpers/trackAnalyticsEvents'
import {
  AggregatedStoppage,
  DurationInterval,
  IssueGroupingKey,
  IssueSortingKey,
  TimeStep
} from '../types'

import {useAggregatedStoppages} from './useAggregatedStoppages'
import {useQueryParamValue} from './useQueryParamValue'

type SortedStoppagesProps = {
  plantId: string
  groupBy?: IssueGroupingKey
  timeRange: DateRange<Date>
  selectedMainEquipments?: string[]
  selectedStoppages?: StoppageKind[]
  selectedStoppageCodes?: string[]
  selectedEquipmentNumbers?: string[]
  timeStep?: TimeStep
  stoppageType?: StoppageType[]
  durationInterval?: DurationInterval
}

export const useSortedStoppages = ({
  plantId,
  groupBy,
  timeRange,
  selectedMainEquipments,
  selectedStoppages,
  selectedStoppageCodes,
  selectedEquipmentNumbers,
  timeStep,
  stoppageType,
  durationInterval
}: SortedStoppagesProps) => {
  const trackAnalyticsEvent = useTrackDowntimeAnalyticsEvent()
  const matchingEventNames = useGetEventNamesByRoutePath()

  const [groupingKey] = useQueryParamValue<IssueGroupingKey>(TOP_ISSUES_GROUP_BY)
  const [topIssuesGroupingKey, setTopIssuesGroupingKey] = useState(
    groupBy || groupingKey || IssueGroupingKey.STOPPAGE_CODE
  )
  const [sortingKey] = useQueryParamValue<IssueSortingKey>(TOP_ISSUES_SORT_BY, {
    enabled: topIssuesGroupingKey !== IssueGroupingKey.TIME_INTERVAL
  })
  const [topIssuesSortingKey, setTopIssuesSortingKey] = useState(
    sortingKey || IssueSortingKey.DURATION
  )

  const aggregatedStoppagesQuery = useAggregatedStoppages(plantId, {
    from: timeRange.from,
    to: timeRange.to,
    groupBy: topIssuesGroupingKey,
    ...(selectedMainEquipments && {mainEquipmentNumbers: selectedMainEquipments}),
    ...(selectedEquipmentNumbers && {equipmentNumbers: selectedEquipmentNumbers}),
    ...(selectedStoppages && {kinds: selectedStoppages}),
    ...(selectedStoppageCodes && {stoppageCodes: selectedStoppageCodes}),
    ...(stoppageType && {stoppageType}),
    ...(durationInterval && {durationInterval}),
    ...(topIssuesGroupingKey === IssueGroupingKey.TIME_INTERVAL && {timeStep})
  })

  let entries: AggregatedStoppage[] = []
  if (aggregatedStoppagesQuery.data) {
    if (topIssuesGroupingKey === IssueGroupingKey.TIME_INTERVAL) {
      entries = orderBy(aggregatedStoppagesQuery.data, 'timeStepStart', 'desc')
    } else {
      if (topIssuesSortingKey === IssueSortingKey.DURATION) {
        entries = orderBy(aggregatedStoppagesQuery.data, 'hoursStopped', 'desc')
      }
      if (topIssuesSortingKey === IssueSortingKey.FREQUENCY) {
        entries = orderBy(aggregatedStoppagesQuery.data, 'stoppagesCount', 'desc')
      }
    }
  }

  const handleSetTopIssuesGroupingKey = useCallback(
    (groupBy: IssueGroupingKey) => {
      if (matchingEventNames?.groupingEventName)
        trackAnalyticsEvent(matchingEventNames.groupingEventName, {group: groupBy})
      setTopIssuesGroupingKey(groupBy)
    },
    [matchingEventNames?.groupingEventName, trackAnalyticsEvent]
  )
  const handleSetTopIssuesSortingKey = useCallback(
    (sortBy: IssueSortingKey) => {
      if (matchingEventNames)
        trackAnalyticsEvent(matchingEventNames.sortingEventName, {sorting: sortBy})
      setTopIssuesSortingKey(sortBy)
    },
    [matchingEventNames, trackAnalyticsEvent]
  )

  return {
    topIssuesSortingKey,
    setTopIssuesSortingKey: handleSetTopIssuesSortingKey,
    topIssuesGroupingKey,
    setTopIssuesGroupingKey: handleSetTopIssuesGroupingKey,
    sortedStoppages: entries,
    aggregatedStoppagesQuery
  }
}
