import {AppName} from '@hconnect/common/hproduce'
import {StatusWithNoneAndCancellationAndApproverAndWorkOrderStates} from '@hconnect/common/types'

import {
  PaginationResponse,
  TaskSource,
  HProduceTask,
  HProduceTaskResponse,
  TaskCompletionStatus
} from '../types'

export const mapTasks = ({
  items,
  ...rest
}: PaginationResponse<HProduceTaskResponse>): PaginationResponse<HProduceTask> => ({
  ...rest,
  items: items.map(({completionStatus, ...rest}) => ({
    status: mapTaskCompletionStatus(completionStatus),
    ...rest
  }))
})

export const mapTaskCompletionStatus = (
  status: TaskCompletionStatus
): StatusWithNoneAndCancellationAndApproverAndWorkOrderStates => {
  switch (status) {
    case TaskCompletionStatus.Pending:
      return 'pending'
    case TaskCompletionStatus.Ongoing:
      return 'ongoing'
    case TaskCompletionStatus.Done:
      return 'done'
    case TaskCompletionStatus.Cancelled:
      return 'cancelled'
    case TaskCompletionStatus.None:
      return 'none'
    case TaskCompletionStatus.WaitingForApproval:
      return 'waitingForApproval'
    case TaskCompletionStatus.Approved:
      return 'approved'
    case TaskCompletionStatus.Rejected:
      return 'rejected'
  }
}

const SERVER_APP_NAME_MAP: Record<TaskSource, AppName> = {
  [TaskSource.COCKPIT]: 'Cockpit',
  [TaskSource.EASY_POM]: 'easyPOM'
}

export const mapToAppName = (source: TaskSource): AppName =>
  SERVER_APP_NAME_MAP[source] ?? 'HProduce'
