import {CompletionSection} from '@hconnect/common/components/completion'
import {ContentBox} from '@hconnect/common/components/ContentBox'
import {appUrl} from '@hconnect/common/hproduce/config'
import {EASY_POM_HOST, easyPOM} from '@hconnect/common/hproduce/config/easyPOM'
import {DASHBOARD_SEGMENT} from '@hconnect/common/hproduce/consts'
import {getCurrentDifficulty} from '@hconnect/common/utils'
import {ChevronRight} from '@mui/icons-material'
import {Link} from '@mui/material'
import React, {FC} from 'react'
import {useParams} from 'react-router'
import {generatePath} from 'react-router-dom'

import {usePlantDifficultySummaries} from '../hooks/usePlantDifficultySummaries'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'

const SCORE_PROGRESS_TITLE = `Plant operating model - ${easyPOM.name}`

type PathParameter = {
  plantId: string
}

export const ScoreProgressSection: FC = () => {
  const {performancePrefix} = useTranslationPrefix()
  const {plantId} = useParams<PathParameter>()
  if (!plantId) throw new Error('Missing plantId prop')

  const {data: difficultySummaries} = usePlantDifficultySummaries(plantId)

  const linkPath = `${appUrl(EASY_POM_HOST)}${generatePath(`/:plantId/${DASHBOARD_SEGMENT}`, {
    plantId
  })}`

  return (
    <Link
      href={linkPath}
      data-test-id="score-progress-section-link-to-easypom"
      sx={(theme) => ({
        textDecoration: 'none',
        '&:hover': {
          '& .card-chevron, .card-name': {
            color: theme.palette.primary.main
          }
        }
      })}
    >
      <ContentBox
        mode="max100PercentOfParentHeight"
        title={SCORE_PROGRESS_TITLE}
        afterTitle={<ChevronRight />}
        bodyWithPadding
        data-test-id="score-progress-section"
      >
        {difficultySummaries && (
          <CompletionSection
            translationPrefix={performancePrefix}
            activeDifficulty={getCurrentDifficulty(difficultySummaries)}
            difficultySummaries={difficultySummaries}
            data-test-id="completion-card-progress"
          />
        )}
      </ContentBox>
    </Link>
  )
}
