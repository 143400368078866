import {NavItem} from '@hconnect/uikit/src/lib2'
import {useTranslation} from 'react-i18next'
import {generatePath, useParams} from 'react-router-dom'

import {checkFeatureFlag} from '../../shared/helpers/featureFlagHelpers'
import {useNavAccessContext} from '../../shared/hooks/useNavAccessContext'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'

import {
  DOWNTIME,
  PERFORMANCE,
  STATUS,
  GLOBAL_KILN_STATUS,
  PLANT_PERFORMANCE,
  PLANT_DOWNTIME
} from './routing'

type PathParameter = {
  plantId?: string
}

export const useGetPerformanceDashboardLinks = (): NavItem[] => {
  const {plantId} = useParams<PathParameter>()
  const {allowMultipleLocations, getRedirectURL, getRedirectStatusURL} = useNavAccessContext()
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const translationFn = (key: string) => t(`${performancePrefix}.${key}`)

  const singleLocationStatus = getRedirectStatusURL()
  const singleLocationPerformance = getRedirectURL()

  const showGlobalKilnMillStatus = checkFeatureFlag('enableKilnMillGlobalStatus')

  if (!showGlobalKilnMillStatus) {
    return [
      {
        url: generatePath(
          !allowMultipleLocations() && singleLocationPerformance
            ? singleLocationPerformance
            : plantId
              ? generatePath(PLANT_PERFORMANCE, {plantId})
              : PERFORMANCE
        ),
        label: translationFn('navPages.performance')
      },
      {
        // TODO: shouldn't this be STATUS?
        url: generatePath(GLOBAL_KILN_STATUS),
        label: translationFn('navPages.status')
      },
      {
        url: plantId ? generatePath(PLANT_DOWNTIME, {plantId}) : DOWNTIME,
        label: translationFn('navPages.downtime')
      }
    ]
  }

  return [
    {
      url: generatePath(
        !allowMultipleLocations() && singleLocationStatus ? singleLocationStatus : STATUS // TODO: shouldn't this be GLOBAL_KILN_STATUS?
      ),
      label: translationFn('navPages.status')
    },
    {
      url: generatePath(
        !allowMultipleLocations() && singleLocationPerformance
          ? singleLocationPerformance
          : plantId
            ? generatePath(PLANT_PERFORMANCE, {plantId})
            : PERFORMANCE
      ),
      label: translationFn('navPages.performance')
    },
    {
      url: plantId ? generatePath(PLANT_DOWNTIME, {plantId}) : DOWNTIME,
      label: translationFn('navPages.downtime')
    }
  ]
}
