import {FlexPage} from '@hconnect/common/components/FlexPage'
import {useWidth} from '@hconnect/common/hooks/useWidth'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import {EventsTableLayout} from '../layouts'

export const Tasks: React.FC = () => {
  const {t} = useTranslation()
  const {tasksPrefix} = useTranslationPrefix()
  const breakPoint = useWidth()
  const isSmall = breakPoint === 'xs' || breakPoint === 'sm'

  return (
    <FlexPage useViewportHeight={!isSmall} title={t(`${tasksPrefix}.pageTitle`)}>
      <EventsTableLayout translationPrefix={tasksPrefix} />
    </FlexPage>
  )
}
