export function getUrlParam<T = string>(
  params: URLSearchParams,
  paramName: string,
  parser?: (value: string) => T
): T | undefined {
  if (!params.has(paramName)) return undefined
  const value = params.get(paramName)!

  return parser ? parser(value) : (value as unknown as T)
}

export function setBooleanUrlParam(
  params: URLSearchParams,
  paramName: string,
  value: boolean
): void {
  params.set(paramName, value.toString())
}

export function getBooleanUrlParam(params: URLSearchParams, paramName: string): boolean {
  const param = getUrlParam(params, paramName, (value) => value === 'true')

  return param ?? false
}

const URL_PARAM_ARRAY_SEPARATOR = ','

export function setArrayUrlParam<T = string>(
  params: URLSearchParams,
  paramName: string,
  values: T[]
): void {
  params.set(paramName, values.join(URL_PARAM_ARRAY_SEPARATOR))
}

export function getArrayUrlParam<T extends string = string>(
  params: URLSearchParams,
  paramName: string
): T[] {
  const param = getUrlParam<T[]>(
    params,
    paramName,
    (value) => value?.split(URL_PARAM_ARRAY_SEPARATOR) as T[]
  )

  return param ?? []
}
