import {DashboardLink} from '@hconnect/common/components/downtime/DashboardLink'
import {Flag, PageTitle, mergeSxs, BackToLink} from '@hconnect/uikit/src/lib2'
import {Box, Stack, SxProps, Theme, useMediaQuery} from '@mui/material'
import React, {memo, ReactNode} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {
  useGetEventNamesByRoutePath,
  useTrackDowntimeAnalyticsEvent
} from '../helpers/trackAnalyticsEvents'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'

type StatusPageHeaderProps = {
  countryCode?: string
  title?: string
  backButtonProps?: {
    targetName: string
    target: string
  }
  onBackButtonClick?: () => void
  plantId?: string
  showDashboardLink?: boolean
  filterComponent?: ReactNode
  afterTitle?: ReactNode
  sx?: SxProps<Theme>
  afterFilters?: ReactNode
}

export const StatusPageHeader = memo<StatusPageHeaderProps>(
  ({
    sx,
    countryCode,
    title,
    filterComponent,
    backButtonProps,
    plantId,
    showDashboardLink,
    onBackButtonClick,
    afterTitle = null,
    afterFilters = null
  }) => {
    const {t} = useTranslation()
    const {performancePrefix} = useTranslationPrefix()
    const navigate = useNavigate()
    const trackAnalyticsEvent = useTrackDowntimeAnalyticsEvent()
    const matchedRouteNames = useGetEventNamesByRoutePath()

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

    const handleNavigate = (target: string) => {
      if (matchedRouteNames?.navigateBackEventName) {
        trackAnalyticsEvent(matchedRouteNames.navigateBackEventName, {})
      }
      onBackButtonClick?.()
      navigate(target)
    }

    return (
      <>
        <Stack
          direction={{xs: 'column', sm: 'row'}}
          justifyContent={'space-between'}
          flexWrap="wrap"
          mb={afterFilters ? 1 : 3}
          gap={1}
          sx={mergeSxs(sx, (theme) => ({
            justifyContent: 'flex-end',
            [theme.breakpoints.down('sm')]: {
              mb: theme.spacing(2)
            }
          }))}
        >
          <Box display="flex" flexDirection="column" justifyContent="space-between" mr="auto">
            {backButtonProps && (
              <BackToLink
                navigate={handleNavigate}
                targetName={backButtonProps.targetName}
                target={backButtonProps.target}
              />
            )}
            <PageTitle
              mx={3}
              mb={!backButtonProps ? 2 : 0}
              sx={{display: 'inline-flex', alignItems: 'center'}}
              data-test-id="page-title"
            >
              {countryCode && (
                <Flag
                  countryCode={countryCode}
                  alt={countryCode}
                  sx={{
                    verticalAlign: 'text-bottom',
                    mr: 1
                  }}
                />
              )}
              {title || countryCode}
            </PageTitle>
            {afterTitle}
          </Box>
          {filterComponent && (
            <Box
              display="flex"
              flexDirection={{xs: 'column', sm: 'row'}}
              justifyContent="space-between"
              gap={1}
              m={{xs: 2, sm: 0}}
            >
              {filterComponent}
              {showDashboardLink && plantId && (
                <DashboardLink
                  title={t(`${performancePrefix}.goToDashboard`)}
                  plantId={plantId}
                  variant="outlined"
                  styleVariant="onBlue"
                  fullWidth={isMobile}
                />
              )}
            </Box>
          )}
        </Stack>
        <Box mb={3}>{afterFilters}</Box>
      </>
    )
  }
)
