import {TASKS_ASSIGNED_TO_ME_PARAM_KEY} from '@hconnect/common/hproduce/consts'
import {useSearchParams} from 'react-router-dom'

import {
  getArrayUrlParam,
  getBooleanUrlParam,
  setArrayUrlParam,
  setBooleanUrlParam
} from '../../shared/helpers'
import {TaskSource} from '../types'
import {isTaskSource} from '../utils'

const ParamName = {
  ASSIGNED_TO_ME: TASKS_ASSIGNED_TO_ME_PARAM_KEY,
  SOURCES: 'sources'
} as const

export type TasksPageQueryParams = {
  assignedToMe?: boolean
  sources?: TaskSource[]
}

function urlParamsToTasksPageQueryParams(params: URLSearchParams): TasksPageQueryParams {
  const assignedToMe = getBooleanUrlParam(params, ParamName.ASSIGNED_TO_ME)
  const sources = getArrayUrlParam<TaskSource>(params, ParamName.SOURCES)
  const sanitizedSources = sources.filter(isTaskSource)

  return {assignedToMe, sources: sanitizedSources}
}

export function tasksPageQueryParamsToUrlParams(
  searchParams: TasksPageQueryParams
): URLSearchParams {
  const params = new URLSearchParams()

  if (searchParams.assignedToMe) {
    setBooleanUrlParam(params, ParamName.ASSIGNED_TO_ME, searchParams.assignedToMe)
  } else {
    params.delete(ParamName.ASSIGNED_TO_ME)
  }
  if (searchParams.sources?.length) {
    setArrayUrlParam(params, ParamName.SOURCES, searchParams.sources)
  } else {
    params.delete(ParamName.SOURCES)
  }
  return params
}

type UseTasksPageQueryParamsReturn = [
  TasksPageQueryParams,
  {
    setParams: (newParams: TasksPageQueryParams) => void
    updateParams: (newParams: Partial<TasksPageQueryParams>) => void
  }
]

export const useTasksPageQueryParams = (): UseTasksPageQueryParamsReturn => {
  const [params, setSearchParams] = useSearchParams()
  const searchParams = urlParamsToTasksPageQueryParams(params)

  const setParams = (newParams: TasksPageQueryParams) => {
    setSearchParams(tasksPageQueryParamsToUrlParams(newParams))
  }

  const updateParams = (newParams: Partial<TasksPageQueryParams>) => {
    setParams({...searchParams, ...newParams})
  }

  return [searchParams, {setParams, updateParams}]
}
