import {
  Iso8601,
  StatusWithNoneAndCancellationAndApproverAndWorkOrderStates,
  User
} from '@hconnect/common/types'

export enum TaskSource {
  EASY_POM = 'EasyPom',
  COCKPIT = 'Cockpit'
}

export enum TaskCompletionStatus {
  Pending = 'Pending',
  Ongoing = 'Ongoing',
  Done = 'Done',
  Cancelled = 'Cancelled',
  None = 'None',
  WaitingForApproval = 'WaitingForApproval',
  Approved = 'Approved',
  Rejected = 'Rejected'
}

export type PaginationResponse<T> = {
  items: T[]
  totalItemCount: number
}

export type HProduceTaskResponse = {
  externalId: string
  id: string
  plantId: string
  source: TaskSource
  title: string
  description: string
  dueDate: Iso8601
  created: Iso8601
  completionStatus: TaskCompletionStatus
  assignees: User[]
}

export type HProduceTask = {
  status: StatusWithNoneAndCancellationAndApproverAndWorkOrderStates
  created: Iso8601
  description: string
  dueDate: Iso8601
  externalId: string
  id: string
  plantId: string
  source: TaskSource
  title: string
  assignees: User[]
}

export enum TasksTableSourceFilterValue {
  SHIFT = 'shift',
  POM = 'pom'
}
