import {ViewMode} from '@hconnect/common/components/eventsList/types'
import {useEndOfPageScrollDebounce} from '@hconnect/common/hooks/useListScrollDebounce'
import {PaginationOptions} from '@hconnect/uikit'
import {Theme, useMediaQuery} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {generatePath, useNavigate} from 'react-router'

import {EventsTableWrapper} from '../components'
import {
  combineSearchParamsToQueryString,
  useTasks,
  useQueryParameterForViewMode,
  useTasksTableFilters,
  viewModeToUrlParams,
  TasksPageQueryParams,
  tasksPageQueryParamsToUrlParams,
  useTasksPageQueryParams,
  useTasksInfiniteQuery
} from '../hooks'
import {TASKS} from '../routing'
import {HProduceTask} from '../types'

const createUrl = (
  nextViewMode: ViewMode<HProduceTask>,
  filtersParams: TasksPageQueryParams
): string => {
  const viewModeParams = viewModeToUrlParams(nextViewMode)
  const tasksFiltersParams = tasksPageQueryParamsToUrlParams(filtersParams)
  const queryString = combineSearchParamsToQueryString(viewModeParams, tasksFiltersParams)
  return `${generatePath(TASKS)}?${queryString}`
}

type Props = {
  translationPrefix: string
}

const DEFAULT_PAGE_SIZE = 50

export const EventsTableLayout: React.FC<Props> = ({translationPrefix}) => {
  const navigate = useNavigate()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const [pageNumber, changePage] = useState<number>(0)
  const [itemsPerPage, setItemsPerPage] = useState<number>(DEFAULT_PAGE_SIZE)
  const [filtersParams] = useTasksPageQueryParams()
  const tasksTableFiltersOptions = useTasksTableFilters({
    translationPrefix,
    onFiltersChanged: () => changePage(0)
  })
  const tasksQueryParams = {
    limit: itemsPerPage,
    pageNumber,
    assignedToMe: tasksTableFiltersOptions.assignedToMe,
    sources: tasksTableFiltersOptions.sourceFilters
  }
  const {data: desktopData, isLoading: isDesktopDataLoading} = useTasks(tasksQueryParams, {
    enabled: !isMobile
  })
  const {
    data: mobileTasksData,
    isLoading: isMobileDataLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage
  } = useTasksInfiniteQuery(tasksQueryParams, {
    enabled: isMobile
  })
  const isLoading = isMobile ? isMobileDataLoading : isDesktopDataLoading
  const mobileTasks = mobileTasksData?.pages.flatMap((page) => page) ?? []
  const tasks = isMobile ? mobileTasks : desktopData?.items ?? []
  const shouldFetchNextPage = !!hasNextPage && !isFetchingNextPage

  const {trackScrolling} = useEndOfPageScrollDebounce(() => {
    void fetchNextPage()
  }, shouldFetchNextPage)

  useEffect(() => {
    if (isMobile) {
      document.addEventListener('scroll', trackScrolling)
    }

    return () => document.removeEventListener('scroll', trackScrolling)
  }, [isMobile, trackScrolling])

  useEffect(() => {
    if (isMobile) {
      setItemsPerPage(DEFAULT_PAGE_SIZE)
      changePage(0)
    }
  }, [isMobile])

  const onPaginationChange = (pageNumber: number, itemsPerPage: number) => {
    changePage(pageNumber)
    setItemsPerPage(itemsPerPage)
  }

  const paginationOptions: PaginationOptions = {
    page: pageNumber,
    rowsPerPage: itemsPerPage,
    onPaginationChange: onPaginationChange,
    totalDataLength: desktopData?.totalItemCount ?? 0
  }

  const viewMode = useQueryParameterForViewMode()
  const setViewMode = (nextViewMode: ViewMode<HProduceTask>) => {
    navigate(createUrl(nextViewMode, filtersParams))
  }

  return (
    <EventsTableWrapper
      events={tasks}
      isLoading={isLoading}
      viewMode={viewMode}
      setViewMode={setViewMode}
      paginationOptions={isMobile ? undefined : paginationOptions}
      tableFiltersOptions={tasksTableFiltersOptions}
      timezone={timezone}
      translationPrefix={translationPrefix}
      showBottomProgress={isMobile && hasNextPage && isFetchingNextPage}
    />
  )
}
