import {getPlantName} from '@hconnect/common/hproduce/whiteListCheck'
import {trackEvent, TrackEventData} from '@hconnect/common/logging/Analytics'
import {isObject} from 'lodash'
import {useCallback, useMemo} from 'react'
import {useParams} from 'react-router'
import {matchPath, useLocation} from 'react-router-dom'

import {FiltersWithDateRangeKeys} from '../components/downtime/filters/filters.models'
import {useGlobalContext} from '../hooks/useGlobalContext'
import {
  PLANT_DOWNTIME,
  PLANT_DOWNTIME_STOPPAGES,
  PLANT_DOWNTIME_STOPPAGES_DETAILS,
  PLANT_DOWNTIME_STOPPAGES_DETAILS_FOR_CODE
} from '../routing'
import {IssueGroupingKey, IssueSortingKey} from '../types'

export enum BaseAnalyticsEventName {
  UserEntersDowntimeDashboard = 'userEntersDowntimeDashboard',
  UserClicksOnRcfa = 'userClicksOnRcfa',
  UserSet1LevelGrouping = 'userSet1LevelGrouping',
  UserSet1LevelSorting = 'userSet1LevelSorting',
  UserClicksOn1LevelStoppagesGraph = 'userClicksOn1LevelStoppagesGraph',
  UserOpenStoppagesToAmendList = 'userOpenStoppagesToAmendList',
  UserOpenStoppageToAmendForm = 'userOpenStoppageToAmendForm',
  UserSkipStoppageWithMissingData = 'userSkipStoppageWithMissingData',
  UserAmendStoppage = 'userAmendStoppage',
  UserSet2LevelGrouping = 'userSet2LevelGrouping',
  UserSet2LevelSorting = 'userSet2LevelSorting',
  UserAdjusts2LevelFilter = 'userAdjusts2LevelFilter',
  UserClicksOn2LevelStoppagesGraphBar = 'userClicksOn2LevelStoppagesGraphBar',
  UserSet3LevelSorting = 'userSet3LevelSorting',
  UserAdjusts3LevelFilter = 'userAdjusts3LevelFilter',
  UserAdjusts4LevelFilter = 'userAdjusts4LevelFilter',
  UserAdjustDescriptionColumn = 'userAdjustDescriptionColumn',
  UserClicksOn3LevelStoppagesGraphBar = 'userClicksOn3LevelStoppagesGraphBar',
  UserGoesBackTo3Level = 'userGoesBackTo3Level',
  UserGoesBackTo2Level = 'userGoesBackTo2Level',
  UserGoesBackTo1Level = 'userGoesBackTo1Level'
}

enum Feature {
  DowntimeDashboard = 'Downtime Dashboard'
}

export type GroupingNames =
  | BaseAnalyticsEventName.UserSet2LevelGrouping
  | BaseAnalyticsEventName.UserSet1LevelGrouping
export type SortingNames =
  | BaseAnalyticsEventName.UserSet3LevelSorting
  | BaseAnalyticsEventName.UserSet2LevelSorting
  | BaseAnalyticsEventName.UserSet1LevelSorting
export type FilteringNames =
  | BaseAnalyticsEventName.UserAdjusts4LevelFilter
  | BaseAnalyticsEventName.UserAdjusts3LevelFilter
  | BaseAnalyticsEventName.UserAdjusts2LevelFilter
export type NavigateBackNames =
  | BaseAnalyticsEventName.UserGoesBackTo3Level
  | BaseAnalyticsEventName.UserGoesBackTo2Level
  | BaseAnalyticsEventName.UserGoesBackTo1Level
export const SORT_ANALYTICS_EVENT_NAME_MAP: {
  path: string
  sortingEventName: SortingNames
  groupingEventName?: GroupingNames
  filteringEventName?: FilteringNames
  navigateBackEventName?: NavigateBackNames
}[] = [
  {
    path: PLANT_DOWNTIME_STOPPAGES_DETAILS_FOR_CODE,
    sortingEventName: BaseAnalyticsEventName.UserSet3LevelSorting,
    filteringEventName: BaseAnalyticsEventName.UserAdjusts4LevelFilter,
    navigateBackEventName: BaseAnalyticsEventName.UserGoesBackTo3Level
  },
  {
    path: PLANT_DOWNTIME_STOPPAGES_DETAILS,
    sortingEventName: BaseAnalyticsEventName.UserSet3LevelSorting,
    filteringEventName: BaseAnalyticsEventName.UserAdjusts3LevelFilter,
    navigateBackEventName: BaseAnalyticsEventName.UserGoesBackTo2Level
  },
  {
    path: PLANT_DOWNTIME_STOPPAGES,
    sortingEventName: BaseAnalyticsEventName.UserSet2LevelSorting,
    groupingEventName: BaseAnalyticsEventName.UserSet2LevelGrouping,
    filteringEventName: BaseAnalyticsEventName.UserAdjusts2LevelFilter,
    navigateBackEventName: BaseAnalyticsEventName.UserGoesBackTo1Level
  },
  {
    path: PLANT_DOWNTIME,
    sortingEventName: BaseAnalyticsEventName.UserSet1LevelSorting,
    groupingEventName: BaseAnalyticsEventName.UserSet1LevelGrouping
  }
]
export const useGetEventNamesByRoutePath = () => {
  const location = useLocation()
  return useMemo(
    () => SORT_ANALYTICS_EVENT_NAME_MAP.find(({path}) => matchPath(path, location.pathname)),
    [location.pathname]
  )
}

type BaseAnalyticsEvent =
  | {
      name: BaseAnalyticsEventName.UserEntersDowntimeDashboard
    }
  | {
      name: BaseAnalyticsEventName.UserClicksOnRcfa
    }
  | {
      name: BaseAnalyticsEventName.UserSet1LevelGrouping
      data: {group: IssueGroupingKey}
    }
  | {
      name: BaseAnalyticsEventName.UserSet1LevelSorting
      data: {sorting: IssueSortingKey}
    }
  | {
      name: BaseAnalyticsEventName.UserClicksOn1LevelStoppagesGraph
    }
  | {
      name: BaseAnalyticsEventName.UserOpenStoppagesToAmendList
    }
  | {
      name: BaseAnalyticsEventName.UserOpenStoppageToAmendForm
      data: {equipmentId: string}
    }
  | {
      name: BaseAnalyticsEventName.UserSkipStoppageWithMissingData
      data: {equipmentId: string}
    }
  | {
      name: BaseAnalyticsEventName.UserAmendStoppage
      data: {equipmentId: string}
    }
  | {
      name: BaseAnalyticsEventName.UserSet2LevelGrouping
      data: {group: IssueGroupingKey}
    }
  | {
      name: BaseAnalyticsEventName.UserSet2LevelSorting
      data: {sorting: IssueSortingKey}
    }
  | {
      name: BaseAnalyticsEventName.UserAdjusts2LevelFilter
      data: {filterName: FiltersWithDateRangeKeys}
    }
  | {
      name: BaseAnalyticsEventName.UserClicksOn2LevelStoppagesGraphBar
      data: {group: IssueGroupingKey; groupId: string}
    }
  | {
      name: BaseAnalyticsEventName.UserSet3LevelSorting
      data: {sorting: IssueSortingKey}
    }
  | {
      name: BaseAnalyticsEventName.UserAdjusts3LevelFilter
      data: {filterName: FiltersWithDateRangeKeys}
    }
  | {
      name: BaseAnalyticsEventName.UserClicksOn3LevelStoppagesGraphBar
      data: {group: IssueGroupingKey; groupId: string}
    }
  | {
      name: BaseAnalyticsEventName.UserAdjusts4LevelFilter
      data: {filterName: FiltersWithDateRangeKeys}
    }
  | {
      name: BaseAnalyticsEventName.UserAdjustDescriptionColumn
    }
  | {
      name: BaseAnalyticsEventName.UserGoesBackTo3Level
    }
  | {
      name: BaseAnalyticsEventName.UserGoesBackTo2Level
    }
  | {
      name: BaseAnalyticsEventName.UserGoesBackTo1Level
    }

interface CommonAnalyticsData {
  data: {
    timestamp: string
    userId: string
    plantId: string
    plantName: string
    country: string
  }
}

type AnalyticsEvent = BaseAnalyticsEvent & {data: CommonAnalyticsData['data']}

export const trackAnalyticsEvent = <T extends AnalyticsEvent['name']>(
  eventName: T,
  data: TrackEventData
) => trackEvent(eventName, {product: 'hproduce', ...data})

type PathParameter = {
  plantId: string
}

export const useTrackDowntimeAnalyticsEvent = () => {
  const {plantId} = useParams<PathParameter>()
  const {user} = useGlobalContext()

  return useCallback(
    <T extends AnalyticsEvent['name']>(
      eventName: T,
      data: Extract<BaseAnalyticsEvent, {name: T}> extends {data: infer D}
        ? D
        : Record<string, never>
    ) => {
      if (plantId) {
        const plantName = getPlantName(plantId)
        const {userId, country} = user
        trackAnalyticsEvent(eventName, {
          timestamp: new Date().toISOString(),
          userId,
          plantId,
          plantName,
          country,
          feature: Feature.DowntimeDashboard,
          ...(isObject(data) ? data : {})
        })
      }
    },
    [plantId, user]
  )
}
