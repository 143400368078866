import {PageContainer} from '@hconnect/uikit/src/lib2'
import {Box, Paper, Theme, useMediaQuery} from '@mui/material'
import React, {useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, useLocation, useParams} from 'react-router-dom'

import {useNavAccessContext} from '../../shared/hooks/useNavAccessContext'
import {CommentDialog} from '../components/CommentDialog'
import {DataContentWrapper} from '../components/DataContentWrapper'
import {DateTimeRangePicker} from '../components/DateTimeRangePicker'
import {KpiTabs} from '../components/KpiTabs'
import {AcVsPlContainer} from '../containers/AcVsPlContainer'
import {MobileAcVsPlContainer} from '../containers/MobileAcVsPlContainer'
import {StatusPageHeader} from '../containers/StatusPageHeader'
import {filterCommentsByLocation, tabIndexFiltered} from '../helpers'
import {useKpiValue} from '../hooks/kpi/useKpiValue'
import {useComments} from '../hooks/useComments'
import {useHierarchyData} from '../hooks/useHierarchy'
import {useTimeRange} from '../hooks/useTimeRange'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {AREA_KPI, PERFORMANCE} from '../routing'
import {CommentLocationData, HierarchyNodeType, KpisList, TimeRange} from '../types'

type PathParameter = {
  locationId?: string
}

type LocationsId = Partial<{
  areaId: string
  regionId: string
  countryId: string
}>
const pathToLocationsId = (path: string): LocationsId => {
  const [areaId, regionId, countryId] = path.split('.')
  return {areaId, regionId, countryId}
}

export const ActualVsPlanned: React.FC = () => {
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const {locationId} = useParams<PathParameter>()
  const {from, to} = useTimeRange()
  const {search} = useLocation()
  const dateRange: TimeRange = {startDate: from.toJSON(), endDate: to.toJSON()}
  const {allowMultiplePlants} = useNavAccessContext()

  const hierarchy = useHierarchyData()

  const [currentTab, setCurrentTab] = React.useState<KpisList>(KpisList.ClinkerProduction)
  const [commentLocationData, setCommentLocationData] = React.useState<
    CommentLocationData | undefined
  >(undefined)

  const {data: comments = []} = useComments(
    {
      timeRange: dateRange,
      locationId: locationId || HierarchyNodeType.Global
    },
    {
      select: (data) => data.filter((comment) => comment.topic === currentTab)
    }
  )
  useEffect(() => {
    // Note: Reset tab to default on location change
    setCurrentTab(KpisList.ClinkerProduction)
  }, [locationId])

  const {data, hasQuarterlyData, isLoading, error, refetch} = useKpiValue(currentTab, locationId)

  const backButtonProps = useMemo(() => {
    const locationsId = data?.locationData?.path
      ? pathToLocationsId(data.locationData.path)
      : undefined
    if (locationsId?.areaId && (locationsId.countryId || locationsId.regionId)) {
      return {
        targetName: t(`${performancePrefix}.goBackLabels.backToArea`),
        target: generatePath(AREA_KPI, {locationId: locationsId.areaId}) + search
      }
    }
    if (allowMultiplePlants() && locationId) {
      return {
        targetName: t(`${performancePrefix}.goBackLabels.backToGlobal`),
        target: PERFORMANCE
      }
    }
  }, [allowMultiplePlants, data?.locationData?.path, search, locationId, t, performancePrefix])

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const renderContent = () => {
    return (
      data &&
      (isMobile ? (
        <Box data-test-id="ac-vs-pl-page">
          <KpiTabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
          <MobileAcVsPlContainer
            data={data}
            hasQuarterlyData={hasQuarterlyData}
            currentTab={currentTab}
            hideCharts={!tabIndexFiltered.includes(currentTab)}
            setCommentLocationData={setCommentLocationData}
            comments={comments}
          />
        </Box>
      ) : (
        <Paper sx={{padding: 2}} data-test-id="ac-vs-pl-page">
          <KpiTabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
          <AcVsPlContainer
            data={data}
            hasQuarterlyData={hasQuarterlyData}
            currentTab={currentTab}
            hideCharts={!tabIndexFiltered.includes(currentTab)}
            setCommentLocationData={setCommentLocationData}
            comments={comments}
          />
        </Paper>
      ))
    )
  }

  return (
    <PageContainer>
      <Box pb={2}>
        <StatusPageHeader
          filterComponent={<DateTimeRangePicker type="performance" />}
          countryCode={data?.locationData?.countryCode}
          title={
            isLoading ? '' : data?.locationData?.name || t(`${performancePrefix}.global.pageTitle`)
          }
          backButtonProps={backButtonProps}
        />
      </Box>

      <DataContentWrapper
        isLoading={isLoading}
        retryFunction={refetch}
        error={error}
        data={data}
        renderContent={renderContent}
      />

      {commentLocationData && (
        <CommentDialog
          topic={currentTab}
          handleClose={() => setCommentLocationData(undefined)}
          commentLocationData={commentLocationData}
          hierarchy={hierarchy}
          comments={
            hierarchy
              ? filterCommentsByLocation(comments, hierarchy, commentLocationData.locationData.id)
              : []
          }
        />
      )}
    </PageContainer>
  )
}
