import {AxiosError} from 'axios'
import qs from 'qs'
import {useInfiniteQuery, UseInfiniteQueryOptions} from 'react-query'

import {ApiPath} from '../../shared/consts'
import {useAxios} from '../../shared/hooks/useApi'
import {mapTasks} from '../mappers'
import {PaginationResponse, HProduceTask, HProduceTaskResponse} from '../types'

import {TasksParams} from './useTasks'

const TasksQueryKey = 'tasks'

export const useTasksInfiniteQuery = (
  params: TasksParams,
  options?: UseInfiniteQueryOptions<
    PaginationResponse<HProduceTaskResponse>,
    AxiosError,
    HProduceTask[]
  >
) => {
  const axiosInstance = useAxios()
  const {limit, sources, assignedToMe} = params

  return useInfiniteQuery<PaginationResponse<HProduceTaskResponse>, AxiosError, HProduceTask[]>({
    queryKey: [TasksQueryKey, limit, sources, assignedToMe],
    queryFn: async ({pageParam: offset = 0}) => {
      const response = await axiosInstance.get<PaginationResponse<HProduceTaskResponse>>(
        ApiPath.TASKS,
        {
          params: {
            offset,
            limit,
            sources,
            includeOnlyAssignedToUser: assignedToMe
          },
          paramsSerializer: (params) => qs.stringify(params, {arrayFormat: 'repeat'})
        }
      )

      return response.data ?? []
    },
    getNextPageParam: (lastPage, pages) => {
      // in infinite query this will determine the offset for the next page as 'pageParam' prop in the queryFn,
      // and if it returns false, it means there are no more pages (hasNextPage property will be false)
      return lastPage.items.length === limit ? pages.length * limit : false
    },
    select: (data) => ({...data, pages: data.pages.map((page) => mapTasks(page).items)}),
    ...options
  })
}
