import {EventAttachmentUpload} from '@hconnect/common/components/attachments'
import {ConfigContext} from '@hconnect/common/hooks/ConfigContext'
import {StoppageDto as Stoppage, AttachmentFile} from '@hconnect/common/types'
import {stoppageDefaultObject} from '@hconnect/common/utils'
import {LoadingSwitcher} from '@hconnect/uikit/src/lib2'
import {AxiosError} from 'axios'
import React, {useMemo, useState} from 'react'

import {checkFeatureFlag, FeatureFlag} from '../../shared/helpers/featureFlagHelpers'
import {useHasPermission} from '../hooks/usePermission'
import {useStoppageCreate} from '../hooks/useStoppageCreate'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {StoppageCreate as StoppageCreateDto} from '../types'

import {StoppageFormContainer} from './StoppageFormContainer'

const createNew = (initialData: Partial<Stoppage> = {}): Partial<Stoppage> => {
  return stoppageDefaultObject<Partial<Stoppage>>(initialData)
}

type StoppageCreateProps = {
  plantId: string
  'data-test-id'?: string
  initialData?: Partial<Stoppage>
  doClose(): void
  onSuccessSubmit?(): void
  onError?(error: AxiosError): void
  config?: ConfigContext<FeatureFlag>
  renderFormHeaderActions?: (props: {doClose: () => void}) => React.ReactNode
  renderFormFooterActions?: (props: {
    doSubmit: () => void
    doClose: () => void
    isDirty: boolean
    isSubmitDisabled: boolean
  }) => React.ReactNode
  bodyWithPadding?: boolean
  withSalesImpact?: boolean
  withPriority?: boolean
  withCategory?: boolean
  eventTypeLabelPrefix?: string
}

export const StoppageCreate: React.FC<StoppageCreateProps> = ({
  doClose,
  plantId,
  onSuccessSubmit,
  onError,
  initialData,
  renderFormHeaderActions,
  renderFormFooterActions,
  bodyWithPadding = true,
  eventTypeLabelPrefix,
  withPriority,
  withCategory,
  ...props
}) => {
  const {performancePrefix} = useTranslationPrefix()
  const isMovieAttachmentEnabled = checkFeatureFlag('movieAttachment')
  const [files, setFiles] = useState<Array<AttachmentFile>>([])
  const hasPermission = useHasPermission(plantId)

  const createAction = useStoppageCreate(plantId, {
    onSuccess: onSuccessSubmit,
    onError: onError
  })

  const item: Partial<Stoppage> = useMemo(() => createNew(initialData), [initialData])

  const doSubmit = React.useCallback(
    (item: StoppageCreateDto) => {
      createAction.mutate({
        ...item,
        attachments: files
      })
    },
    [createAction, files]
  )

  const disableStartEndChange = !!(initialData?.stoppageStart || initialData?.stoppageEnd)
  const durationEditOptions = {
    disableStart: disableStartEndChange,
    disableEnd: disableStartEndChange
  }

  return (
    <LoadingSwitcher isLoading={createAction.isLoading}>
      <StoppageFormContainer
        data-test-id={props['data-test-id']}
        isCreateMode={true}
        item={item}
        doClose={doClose}
        doSubmit={doSubmit}
        plantId={plantId}
        renderFormHeaderActions={renderFormHeaderActions}
        renderFormFooterActions={renderFormFooterActions}
        bodyWithPadding={bodyWithPadding}
        withSalesImpact={props.withSalesImpact}
        withPriority={withPriority}
        withCategory={withCategory}
        eventTypeLabelPrefix={eventTypeLabelPrefix}
        durationEditOptions={durationEditOptions}
      >
        <EventAttachmentUpload
          setAttachments={setFiles}
          attachments={files}
          isMovieAttachmentEnabled={isMovieAttachmentEnabled}
          hasPermission={hasPermission}
          translationPrefix={performancePrefix}
        />
      </StoppageFormContainer>
    </LoadingSwitcher>
  )
}
