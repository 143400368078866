import {EventTaskChecklists} from '@hconnect/common/components/hrocChecklists'
import {Tag} from '@hconnect/common/components/Tag'
import {getTranslationKey} from '@hconnect/common/utils/translation.utils'
import {Box} from '@mui/material'
import {useLocation} from 'react-router'

import {checkFeatureFlag} from '../../shared/helpers/featureFlagHelpers'
import {useHasPermission} from '../../shared/hooks/usePermission'
import {useTaskChecklists} from '../hooks'
import {type HProduceTask} from '../types'

type Props = {
  task: HProduceTask
  translationPrefix?: string
}

export const TaskChecklistsContainer = ({task, translationPrefix}: Props) => {
  const {pathname, search} = useLocation()
  const hasPermission = useHasPermission(task.plantId)
  const canViewChecklists = hasPermission('VIEW_CHECKLISTS')
  const areChecklistsEnabled = checkFeatureFlag('checklists')
  const isCockpitTask = task?.source === 'Cockpit'
  const isTaskChecklistsEnabled = task && areChecklistsEnabled && canViewChecklists && isCockpitTask
  const {data: taskChecklists = [], isLoading: isLoadingChecklists} = useTaskChecklists(task, {
    enabled: isTaskChecklistsEnabled
  })

  if (!isTaskChecklistsEnabled) {
    return null
  }

  return (
    <Box sx={{my: 1.5}} data-test-id="task-checklists">
      <Tag labelKey={getTranslationKey('shiftEvent.label.safetyChecklists', translationPrefix)}>
        <EventTaskChecklists
          checklists={taskChecklists}
          isLoading={isLoadingChecklists}
          translationPrefix={getTranslationKey('shiftEvent', translationPrefix)}
          referer={{appName: 'HProduce', pathname, search}}
          plantId={task.plantId}
        />
      </Tag>
    </Box>
  )
}
