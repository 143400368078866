import {RemoveFilterButton} from '@hconnect/common/components/filters/RemoveFilterButton'
import {StoppageKind} from '@hconnect/common/types'
import {Checkbox, MenuItem, TextField} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../hooks/useTranslationPrefix'

import {textFieldOnBlueSx} from './filters/filterStyles'

type StoppageTypeItem = {
  type: StoppageKind
  description: string
  hidden?: boolean
}

type Props = {
  value?: StoppageKind[]
  onChange: (stoppage?: StoppageKind[]) => void
  clearFilter: () => void
}

const keyName = 'stoppage'

export const FailureFilter: React.FC<Props> = ({value, onChange, clearFilter}) => {
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const stoppageTypes: StoppageTypeItem[] = [
    {
      type: StoppageKind.Incident,
      description: t(`${performancePrefix}.shiftEvent.eventType.incident`)
    },
    {
      type: StoppageKind.Circumstance,
      description: t(`${performancePrefix}.shiftEvent.eventType.circumstance`)
    },
    {
      type: StoppageKind.PlannedStoppage,
      description: t(`${performancePrefix}.shiftEvent.eventType.plannedStoppage`)
    }
  ]

  return (
    <TextField
      variant="filled"
      sx={textFieldOnBlueSx}
      data-test-id={'filter-stoppageType'}
      fullWidth
      label={t(`${performancePrefix}.shiftEvent.label.failureType`)}
      required={false}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value as unknown as StoppageKind[] | undefined
        if (val?.length === 0) onChange(undefined)
        else onChange(val)
      }}
      value={value ?? []}
      SelectProps={{
        multiple: true,
        renderValue: (args: unknown) =>
          (args as StoppageKind[])
            .map((arg: StoppageKind) => t(`${performancePrefix}.shiftEvent.${keyName}.${arg}`))
            .join(', ')
      }}
      select
    >
      {stoppageTypes.map((option) => (
        <MenuItem data-test-id={`option-${option.type}`} key={option.type} value={option.type}>
          <Checkbox
            checked={!!value?.find((stoppage) => stoppage === option.type)}
            data-test-id={`failure-checkbox-${option.type}`}
          />
          {option.description}
        </MenuItem>
      ))}
      <RemoveFilterButton
        keyName={keyName}
        removeFilter={clearFilter}
        translationPrefix={performancePrefix}
      />
    </TextField>
  )
}
