import {DateRange} from '@hconnect/uikit/src/common'
import {isSameDate} from '@hconnect/uikit/src/common/utils/dateUtils'
import {Box, FormControlLabel, Grid, Paper, Switch, Typography} from '@mui/material'
import {TFunction} from 'i18next'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, useParams} from 'react-router'

import {usePlantTimezone} from '../../shared/hooks/useTimezone'
import {DowntimeFilters} from '../components/downtime/filters/Filters'
import {StoppageDetailsTable} from '../components/downtime/StoppageDetailsTable'
import {TopIssuesByCode} from '../components/downtime/TopIssuesByCode'
import {DOWNTIME_DETAILS_FILTER_OPTIONS} from '../consts'
import {
  getBarTimeFormattedLabelTitle,
  getFailureKindsFromUrl,
  getTimeStepForTitle,
  isUnavailable
} from '../helpers'
import {
  BaseAnalyticsEventName,
  useTrackDowntimeAnalyticsEvent
} from '../helpers/trackAnalyticsEvents'
import {useDowntimeStoppageDetailsStates} from '../hooks/useDowntimeStoppageDetailsStates'
import {usePlantStoppagesDetails} from '../hooks/usePlantStoppagesDetails'
import {useStoppagesStatsTimeRange} from '../hooks/useStoppagesStatsTimeRange'
import {usePlantTimeRange} from '../hooks/useTimeRange'
import {useTimeRangeParam} from '../hooks/useTimeRangeParam'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {PLANT_DOWNTIME_STOPPAGES, PLANT_DOWNTIME_STOPPAGES_DETAILS} from '../routing'
import {IssueGroupingKey, StoppageCode} from '../types'

import {StatusPageHeader} from './StatusPageHeader'

type PathParameter = {
  plantId: string
  groupId: string
  groupType: IssueGroupingKey
}

type DefaultStoppageDetailsProps = {
  stoppageConfig?: StoppageCode[]
}

const getTheTitle = ({
  stoppageCodes,
  groupKey,
  groupId,
  timeRange,
  language,
  performancePrefix,
  translationFn,
  timezone
}: {
  stoppageCodes: StoppageCode[] | undefined
  groupKey: IssueGroupingKey
  groupId: string
  timeRange: DateRange<Date>
  language: string
  performancePrefix: string
  translationFn: TFunction
  timezone: string
}) => {
  if (groupKey === IssueGroupingKey.STOPPAGE_CODE) {
    const stoppageCode = stoppageCodes?.find((stoppageCode) => stoppageCode.code === groupId)
    const text = isUnavailable(groupId)
      ? translationFn(`${performancePrefix}.shiftEvent.stoppage.stoppageTypeOther`)
      : stoppageCode?.text
    return text ?? groupId
  }
  if (groupKey === IssueGroupingKey.TIME_INTERVAL) {
    const timeStepForTitle = getTimeStepForTitle(timeRange, timezone)
    return getBarTimeFormattedLabelTitle(
      timeStepForTitle,
      timeRange.from.toISOString(),
      timeRange.to.toISOString(),
      language,
      timezone
    )
  }
  return groupId
}

export const DefaultStoppageDetails: React.FC<DefaultStoppageDetailsProps> = ({stoppageConfig}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const trackAnalyticsEvent = useTrackDowntimeAnalyticsEvent()
  const {plantId, groupType, groupId} = useParams<PathParameter>()
  if (!plantId || !groupId || !groupType) throw new Error('Missing plantId or stoppage group prop')
  const timezone = usePlantTimezone()
  const timeRange = usePlantTimeRange()
  const [, setTimeRange] = useTimeRangeParam()
  const {stoppagesStatsTimeRange} = useStoppagesStatsTimeRange()
  const setTimeRangeFilter = useCallback(() => {
    if (stoppagesStatsTimeRange && groupType === IssueGroupingKey.TIME_INTERVAL) {
      setTimeRange(stoppagesStatsTimeRange)
    }
  }, [groupType, setTimeRange, stoppagesStatsTimeRange])

  const {
    mainEquipments,
    setMainEquipments,
    equipmentNumbers,
    setEquipmentNumbers,
    failureStoppage,
    setFailureStoppage,
    selectedStoppageCodes,
    setSelectedStoppageCodes
  } = useDowntimeStoppageDetailsStates({groupId, groupType})

  const [showDescriptions, setShowDescriptions] = useState(false)

  const {
    data: stoppagesDetails = [],
    isLoading: stoppageDetailsLoading,
    error: stoppageDetailsError,
    refetch: refetchStoppageDetails
  } = usePlantStoppagesDetails(plantId, {
    ...timeRange,
    ...(mainEquipments && {selectedMainEquipments: mainEquipments?.map((eq) => eq.id)}),
    ...(equipmentNumbers && {selectedEquipments: equipmentNumbers?.map((eq) => eq.id)}),
    ...(failureStoppage && {selectedStoppages: getFailureKindsFromUrl(failureStoppage)}),
    ...(selectedStoppageCodes && {selectedStoppageCodes})
  })

  const timeFramesTitle = useMemo(() => {
    const singleDate = isSameDate(timeRange.from, timeRange.to)
    return timeRange.quickSelectionId
      ? `${t(`${performancePrefix}.quickSelection.${timeRange.quickSelectionId}`)}`
      : `${timeRange.from.toDateString()}${singleDate ? '' : ` - ${timeRange.to.toDateString()}`}`
  }, [timeRange, t, performancePrefix])

  return (
    <>
      <StatusPageHeader
        title={getTheTitle({
          stoppageCodes: stoppageConfig,
          groupKey: groupType,
          groupId,
          timeRange,
          language,
          performancePrefix,
          translationFn: t,
          timezone
        })}
        backButtonProps={{
          target: generatePath(PLANT_DOWNTIME_STOPPAGES, {plantId}),
          targetName: t(`${performancePrefix}.downtime.actions.backToStoppages`)
        }}
        onBackButtonClick={setTimeRangeFilter}
        filterComponent={
          <DowntimeFilters
            failureState={{
              failureStoppage: getFailureKindsFromUrl(failureStoppage),
              setFailureStoppage: (failures) => setFailureStoppage(failures?.join(','))
            }}
            mainEquipmentState={{mainEquipments, setMainEquipments}}
            stoppageCodesState={{
              stoppageCodes: selectedStoppageCodes,
              setStoppageCodes: setSelectedStoppageCodes
            }}
            equipmentNumbersState={{equipmentNumbers, setEquipmentNumbers}}
            options={DOWNTIME_DETAILS_FILTER_OPTIONS[groupType]}
            path={generatePath(PLANT_DOWNTIME_STOPPAGES_DETAILS, {
              plantId,
              groupType,
              groupId
            })}
          />
        }
      />

      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <TopIssuesByCode
            plantId={plantId}
            stoppageConfig={stoppageConfig}
            selectedEquipments={mainEquipments?.map((eq) => eq.id)}
            selectedEquipmentNumbers={equipmentNumbers?.map((eq) => eq.id)}
            selectedStoppages={getFailureKindsFromUrl(failureStoppage)}
            selectedStoppageCodes={selectedStoppageCodes}
            title={t(`${performancePrefix}.downtime.label.downtimes`)}
            issueCount={10}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Paper sx={{padding: (theme) => theme.spacing(1)}}>
            <Box display={'flex'} justifyContent={'space-between'} mb={1}>
              <Box display="flex" m={2} flexDirection="column">
                <Typography variant="subtitle1">{timeFramesTitle}</Typography>
              </Box>
              <FormControlLabel
                control={
                  <Switch
                    checked={showDescriptions}
                    data-test-id="events-show-descriptions-toggle"
                    onChange={() => {
                      setShowDescriptions(!showDescriptions)
                      trackAnalyticsEvent(BaseAnalyticsEventName.UserAdjustDescriptionColumn, {})
                    }}
                    color="primary"
                  />
                }
                label={t(`${performancePrefix}.downtime.actions.toggleDescriptions`)}
              />
            </Box>

            <StoppageDetailsTable
              refetch={() => void refetchStoppageDetails()}
              isLoading={stoppageDetailsLoading}
              error={stoppageDetailsError}
              stoppagesDetails={stoppagesDetails}
              showDescriptions={showDescriptions}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}
